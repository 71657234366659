





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SAVE_PROFITABILITY } from '../store';
import { Profitability, WriteProfitability } from '../types';
import { ApiResponse } from '@/components/types';

const Profitability = namespace('profitability');

@Component({})
export default class ProfitabilityDialog extends Vue {
  @Prop({ type: Object }) profitability?: Profitability;
  @Profitability.Action(SAVE_PROFITABILITY) saveProfitability!: (profitability: WriteProfitability) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  writeProfitability: WriteProfitability = {
    conservator_id: '',
    income: 0,
    labor_costs: 0,
    hourly_rate: 0,
    month: '',
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.writeProfitability = {
        conservator_id: '',
        income: 0,
        labor_costs: 0,
        hourly_rate: 0,
        month: '',
      };

      // @ts-ignore
      this.$refs.form.reset();
      this.$emit('update:profitability', null);
    }, 200);
  }

  @Watch('profitability')
  watchCompensation(profitability: Profitability | null) {
    if (!profitability) return;

    this.writeProfitability.conservator_id = profitability.id;
    this.writeProfitability.income = profitability.income;
    this.writeProfitability.hourly_rate = profitability.hourlyRate;
    this.writeProfitability.labor_costs = profitability.laborCosts;
    this.writeProfitability.month = profitability.month;

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  success() {
    this.dialog = false;
  }

  save() {
    this.error = null;

    return this.saveProfitability(this.writeProfitability);
  }
}
