













import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';

const Correspondence = namespace('correspondence');

@Component({})
export default class DownloadAction extends Vue {
  @Prop({ type: String, default: 'btn-info' }) color!: string;
  @Prop({ type: Boolean, default: false }) large!: boolean;
  @Prop({ type: Boolean, default: false }) icon!: boolean;
  @Prop({ type: String }) label?: string;

  @Prop({ type: String, required: true }) filename!: string;
  @Prop({ type: Function, required: true }) fetchFunction!: () => Promise<ApiResponse<Blob>>;

  loading: boolean = false;
  error: any = null;

  async download() {
    this.error = null;
    this.loading = true;

    const { error, content } = await this.fetchFunction();

    if (error) {
      this.loading = false;
      this.error = error;
      return;
    }

    this.loading = false;

    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.setAttribute('download', this.filename);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
