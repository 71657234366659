















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { endOfMonth, format } from '@/filter/dateFormat';
import { FETCH_PROFITABILITIES } from '../store';
import { Profitability, ProfitabilityDictionary } from '@/modules/profitabilities/types';
import { ApiResponse, Pagination } from '@/components/types';
import ProfitabilityDialog from '../components/ProfitabilityDialog.vue';
import MonthPicker from '@/components/form/MonthPicker.vue';
import AppTable from '@/components/AppTable.vue';
import DownloadAction from '@/components/DownloadAction.vue';
import { routeToLocation } from '@/router';

const Profitability = namespace('profitability');

@Component({
  components: { DownloadAction, AppTable, MonthPicker, ProfitabilityDialog }
})
export default class List extends Vue {
  @Profitability.State('list') profitabilities!: ProfitabilityDictionary;
  @Profitability.Action(FETCH_PROFITABILITIES) fetch!: (month: string) => Promise<ApiResponse>;

  edit: Profitability | null = null;
  loading: boolean = false;
  pagination: Pagination = { sortBy: ['name'], descending: false, itemsPerPage: -1 };
  pdf: any = null;

  get filename(): string {
    if (!this.month) return 'list.pdf';

    return `rentabilität-${format(this.month, '-yyyy')}.pdf`;
  }

  get items() {
    if (this.loading || !this.month) return [];

    return (this.profitabilities[this.month] || []).map((profitability) => ({
      ...profitability,
      profit: profitability.income,
      profitability: profitability.income - profitability.laborCosts
    }));
  }

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.name'), value: 'name', sortable: false },
      { align: 'right', text: this.$i18n.t('common.laborCosts'), value: 'laborCosts' },
      { align: 'right', text: this.$i18n.t('common.hourlyRate'), value: 'hourlyRate' },
      { align: 'right', text: this.$i18n.t('common.profit'), value: 'profit' },
      { align: 'right', text: this.$i18n.t('common.profitability'), value: 'profitability' }
    ];
  }

  itemClass(value: Profitability) {
    if (value.income !== value.incomeBefore) {
      return 'warning';
    }

    return '';
  }

  get month(): string | undefined {
    return this.$route.query['month'] as string;
  }

  set month(month: string | undefined) {
    if (!month) {
      const { month, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {});
      return;
    }

    this.$router.push(routeToLocation({
      ...this.$route,
      query: { ...this.$route.query, month: format(endOfMonth(month), 'yyyy-MM-dd') }
    })).catch(() => {});
  }

  calcProfitability({ profitability, income, laborCosts }: any): string {
    const relative = (income * 100 / laborCosts) - 100;
    const text = laborCosts ? ` (${relative > 0 ? '+' : ''}${(relative).toFixed(2)}%)` : '';

    return `${profitability.toFixed(2)} €${text}`;
  }

  created() {
    if (!this.month) {
      this.month = format(endOfMonth(Date.now()), 'yyyy-MM-dd');
    }
  }

  @Watch('month', { immediate: true })
  async onMonthChange(month: string) {
    if (!month) return;

    this.loading = true;

    try {
      await this.fetch(month);
    } catch (e) {
      console.error(e);
    }

    this.loading = false;
  }

  fetchPDF() {
    return this.$http
      .get('api/conservatorship-management/conservator/profitabilities-pdf', { responseType: 'blob' })
      .then(result => ({ content: result.data }))
      .catch(error => ({ error }));
  }
}
